<template>
  <div class="container-wrap">
    <div class="top-wrap">
      <div>根据微信规则,为避免引起封号，即使群发请遵守以下规则:</div>
      <div>1.发送后，延迟1-3分钟在群内显示；</div>
      <div>
        2.内容不得包含微信禁止关键字(<span
          style="cursor: pointer"
          @click="showKeywordsAction">显示</span>)
      </div>
    </div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="100px" class="ruleForm">
        <el-form-item label="任务名称" prop="name">
            <el-input class="account" v-model="ruleForm.name" placeholder="请输入任务名称" show-word-limit :maxlength="20"></el-input>
             <p>名称只是作为辨别多个自动回复条目之用，并不显示在自动回复中</p>
        </el-form-item>
        <el-form-item label="群发内容" prop="content">
           <GroupMsgCop :removeArr="['invite','videoNum']" :materialArr="ruleForm.content" @onChange="changeMsgContent" ref="groupMsgRef"/>
        </el-form-item>
        <el-form-item label="设置" prop="time">
          <div class="item-wrap">
            <el-checkbox v-model="sendCheck">发送时间间隔</el-checkbox>
            <el-input class="sendtext" type="number" :disabled="sendCheck==false" v-model="ruleForm.sendTime" @change="changeNum" oninput = "value=value.replace(/[^\d.]/g,'')" :min="600"></el-input>
            <div style="margin-left:5px">秒</div>
          </div>
        </el-form-item>

        <div class="foot-wrap">
            <el-button type="primary" size="medium" @click="submitForm('ruleForm')">确 定</el-button>
            <el-button size="medium" @click="backAction">取 消</el-button>
        </div>
    </el-form>
    <KeywordCop ref="keywordDialog" />
  </div>
</template>
<script>
import {unref, reactive,ref,onMounted} from "vue";
import { useRoute, useRouter } from "vue-router";
import TableFoot from "@/components/TableFoot";
import { ElMessageBox,ElMessage } from 'element-plus';
import GroupMsgCop from "@/components/GroupMsg/GroupMsgCop";
import {WelcomeMsgGetDetail,WelcomeMsgCreate} from '@/helper/group'
import _ from "lodash"
import Tool from '@/assets/js/tools'
import KeywordCop from "@/components/KeywordCop";

export default {
  name: "CreateWelcome",
  components: { 
      GroupMsgCop,KeywordCop
  },
  setup() {
    let reClick = false 
    const router = useRouter();
    const SID = useRoute().query.SID;
    const sendCheck=ref(false)
    const keywordDialog = ref(null);
    const groupMsgRef=ref(null)
    const ruleFormRef = ref(null);
    const ruleForm = reactive({
      name:"",
      content:[],
      sendTime:""
    });

    const rules = reactive({
        name: [{ required: true, message: "请填写名称", trigger: "blur" }],
        content: [{validator: validateContent,required: true,message: "请选择群发内容",trigger: "change"}],
    })
    
    //验证
    function validateContent(rule, value, callback) {
      if (!ruleForm.content || ruleForm.content.length <= 0) {
        callback(new Error("请选择群发内容"));
      } else {
        callback();
      }
    }


    onMounted(()=>{
        if(SID){
          WelcomeMsgGetDetail({TaskID:SID}).then(res=>{
            ruleForm.name=res.TaskInfo.TaskName
            // sendCheck.value=res.TaskInfo.TimeSpan==600?false:true
            sendCheck.value=true
            if(sendCheck.value){
              ruleForm.sendTime=res.TaskInfo.TimeSpan
            }
            ruleForm.content=res.MaterialList
            groupMsgRef.value.initData(res.MaterialList);
          })
        }
    })
    
    //展示关键字
    function showKeywordsAction() {
      keywordDialog.value.initCop();
    }

    //检验
    function changeNum(val) {
      if(val<600){
        ruleForm.sendTime=''
      }
    }
    //群发内容变更
    function changeMsgContent(arr) {
      if (arr && arr.length > 0) ruleFormRef.value.clearValidate("content");
      ruleForm.content = arr;
    }

    //返回
    function backAction() {
      router.go(-1);
    }

     /*****************点击事件*********************/
     function fliterMsgContent() {
      let textArr = [];
      let noTextArr = [];

      ruleForm.content.forEach((item) => {
        if (item.Type == 2001) {
          textArr.push(item.MsgContent);
        } else {
          noTextArr.push(item.ID);
        }
      });

      return { textArr: textArr, noTextArr: noTextArr };
    }
   //确定表单
    const submitForm = async () => {
      if (reClick) return;
      reClick = true;
      setTimeout(() => {
        reClick = false;
      }, 3000);
      const form = unref(ruleFormRef);
      if (!form) return;
      try {
        await form.validate();
        if(sendCheck.value&&ruleForm.sendTime<600){
          ElMessage({
            type: "warning",
            message: "时间间隔不能小于10分钟"
          });
          return
        }
        let fliterMsgObj = fliterMsgContent();
        let param = {
          TaskName: ruleForm.name,
          TextContent: fliterMsgObj.textArr,
          MaterialIDs: fliterMsgObj.noTextArr,
          TimeSpan:ruleForm.sendTime || 600
        };
        if(SID){
          param.TaskID=SID
        }
        WelcomeMsgCreate(param).then(res => {
          ElMessage({
            type: "success",
            message: "提交成功"
          });
          router.go(-1);
        });
      } catch (error) {
        console.log(error);
      }
    };
    return {
        rules,
        sendCheck,
        groupMsgRef,
        ruleFormRef,
        ruleForm,
        backAction,
        validateContent,
        changeMsgContent,
        submitForm,
        changeNum,
        showKeywordsAction,
        keywordDialog
    };
  },
};
</script>
<style lang="scss" scoped>
.container-wrap {
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
}

.top-wrap {
  width: 100%;
  margin: auto;
  padding: 10px 20px;
  box-sizing: border-box;
  border: 1px solid #ccc;

  background-color: #f2f2f2;
  font-size: 14px;
  line-height: 24px;
  color: $color-text;
  span {
    color: $color-common;
  }
}
.ruleForm {
  width: 650px;
  margin: 30px 40px;
  p {
    font-size: 12px;
    color: #999;
  }
  .item-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    .item-group-count {
      margin: 0 10px;
    }
    span {
      color: $color-common;
    }
    .sendtext{
        width:90px;
        margin-left: 10px;
    }
  }
}
.foot-wrap {
  width: 100%;

  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
:deep().el-input{
  width: 350px;
}
</style>